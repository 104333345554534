import React from "react"
import Layout from "../components/layout"
import Header1 from "../components/header1"
import HeaderH1 from "../components/headerH1";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";

export default function Contact(props) {
  return (
    <>
      <GatsbySeo title="Contact us and Schedule a Demo Today"
                 description="You will get successful subscriptions if you only hit the contact button once."
      />
      <Layout waveBackgroundColor="#F9FAFB">
        <section className="bg-gray-50 py-20">
          <div className="container max-w-5xl mx-auto m-8">
            {props.location.state &&
            props.location.state.scheduleDemo === true ? (
              <HeaderH1 headerText="Schedule a Demo today"/>
            ) : (
              <HeaderH1 headerText="Contact us"/>
            )}
          </div>

          <div className="max-w-5xl mx-auto m-8 md:mt-0 md:col-span-2 text-l5-secondary">
            <form
              name="Contact"
              action="/contact-success/"
              data-netlify="true"
              method="POST"
            >
              <input type="hidden" name="form-name" value="Contact"/>

              <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first_name"
                      className="block text-m font-medium"
                    >
                      First name
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        autoComplete="given-name"
                        className="mt-1 focus:ring-l5-highlight focus:border-l5-highlight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </label>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last_name"
                      className="block text-m font-medium"
                    >
                      Last name
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        autoComplete="last_name"
                        className="mt-1 focus:ring-l5-highlight focus:border-l5-highlight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </label>
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="company_name"
                      className="block text-m font-medium"
                    >
                      Company name
                      <input
                        type="text"
                        name="company_name"
                        id="company_name"
                        autoComplete="company_name"
                        className="mt-1 focus:ring-l5-highlight focus:border-l5-highlight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </label>
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="email_address"
                      className="block text-m font-medium"
                    >
                      Company E-Mail address
                      <input
                        type="text"
                        name="email_address"
                        id="email_address"
                        autoComplete="email"
                        className="mt-1 focus:ring-l5-highlight focus:border-l5-highlight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </label>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="role" className="block text-m font-medium">
                      Your role
                      <select
                        id="role"
                        name="role"
                        autoComplete="role"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-l5-highlight focus:border-l5-highlight sm:text-sm"
                      >
                        <option>Product Manager</option>
                        <option>Engineer</option>
                      </select>
                    </label>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company_size"
                      className="block text-m font-medium"
                    >
                      Company Size
                      <select
                        id="company_size"
                        name="company_size"
                        autoComplete="company_size"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-l5-highlight focus:border-l5-highlight sm:text-sm"
                      >
                        <option>Just me</option>
                        <option>&lt; 10</option>
                        <option>&lt; 20</option>
                        <option>&lt; 50</option>
                        <option>&lt; 100</option>
                        <option>&lt; 250</option>
                        <option>&lt; 500</option>
                        <option>500 or more</option>
                      </select>
                    </label>
                  </div>

                  <div className="col-span-6">
                    <label htmlFor="message" className="block text-m font-medium">
                      Your message
                      <textarea
                        name="message"
                        className="mt-1 focus:ring-l5-highlight focus:border-l5-highlight block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      ></textarea>
                    </label>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="action-button focus:outline-none focus:shadow-outline"
                >
                  Send
                </button>
              </div>
            </form>
          </div>

          <div className="max-w-5xl mx-auto m-8 md:mt-0 md:col-span-2 text-l5-secondary">
            {props.location.state &&
            props.location.state.scheduleDemo === true ? (
              <></>
            ) : (
              <>
                <Header1 headerText="Press Contact"/>
                <div className="max-w-5xl mx-auto m-8 md:mt-0 md:col-span-2 text-l5-secondary text-center">
                  <p className="mt-12 text-2xl font-bold leading-6 text-gray-800">Please contact:</p>
                  <p className="mt-2 text-xl leading-6 text-gray-800">Alexander Thiele</p>
                  <p className="mt-2 text-xl leading-6 text-gray-800">+49 176 6680 2349</p>
                  <p className="mt-2 text-xl leading-6 text-gray-800">press (at) linkfive.io</p>
                  <p className="mt-12 text-2xl font-bold leading-6 text-gray-800">Or connect on LinkedIn:</p>
                  <p className="mt-2 text-xl leading-6 text-gray-800 underline"><a
                    href="https://www.linkedin.com/in/athiele/" target="_blank" rel="noopener">Alexander Thiele</a></p>

                </div>
              </>
            )}
          </div>
        </section>
      </Layout>
    </>
  )
}
